<template>
  <div class="hello container">
    <!--<h1>{{ msg }}</h1>-->


    <p style="color: red">страница состоит из мелки информационных блоков.</p>
    <p style="color: red">дальше на странице тезисы с других сайтов, черновики, и прочии заметки ..</p>


<pre>
https://biosmartex.com/ru/
https://albnn.com/production/technologies/organic-fertilizers/
https://moybiznes.org/proizvodstvo-grunta
https://www.am-eco.com.ua/linii-dlya-proizvodstva-organicheskih-udobrenij-iz-svinogo-i-korovego-navoza/

http://www.bestgardener.ru/gardening/sad_28.shtml
https://www.docme.su/doc/1089446/oborudovanie-ceha-proizvodstva-gruntosmesej
https://www.dailymotion.com/video/x1861ps
https://kredmash.com/ru/blog/gruntosmesitelnye-ustanovki-i-ikh-primenenie-v-stroitelstve-dorog
http://sapropex.ru/p37.htm
https://insortex.com/equipment/tproduct/281296234-423389863051-smeshivatel-dlya-gruntosmesei-na-baze-ko
https://xn------5cdcbbfdofdlh3ahi2ccsoodbbmb3b2cwb46a.xn--p1ai/fermerstvo-f21/proizvodstvo-grunta-dlya-vyra-ivaniya-rasteniy-t427.html
https://propozitsiya.com/ua/systemy-udobrennya-gruntu-ta-zberezhennya-yogo-organichnoyi-skladovoyi
https://docs.cntd.ru/document/1200074237
</pre>

    <pre v-rc>

Линия производства грунтосмесей и удобрений из сапропеля и торфа
Оборудование для производства органических удобрений и грунтосмесей
ОБОРУДОВАНИЕ ЦЕХА ПРОИЗВОДСТВА ГРУНТОСМЕСЕЙ

Технологическая (производственная) линия получения грунтовых смесей

Технологическая (производственная) линия получения грунтосмесей
Технологическая (производственная) линия представляет собой
Производство грунта
Смешиватель для грунтосмесей на базе компоста

Технология производства органических удобрений
    </pre>


    <div v-rc>
      Плодородный грунт востребован всегда. Многие люди занимаются домашним цветоводством, другие обустраивают теплицы или выращивают рассаду, а некоторые являются заядлыми огородниками, заботящимися о состоянии почвы. Поэтому производство грунта может стать прибыльным и перспективным бизнесом
    </div>


    <div v-rc>
      Плодородный грунт востребован всегда. Многие люди занимаются домашним цветоводством, другие обустраивают теплицы или выращивают рассаду, а некоторые являются заядлыми огородниками, заботящимися о состоянии почвы. Поэтому производство грунта может стать прибыльным и перспективным бизнесом
    </div>


    <div v-rc>
      <h5>Оборудование и техника</h5>
      <h5>ОБОРУДОВАНИЕ ДЛЯ СУХОГО ПРОИЗВОДСТВА САПРОПЕЛЕ-ГРУНТОВЫХ СМЕСЕЙ И УДОБРЕНИЙ</h5>
      <h5>Базовая комплектация линии для производства ??</h5>
      <p>Для обустройства цеха потребуется следующее оборудование:</p>
      <ul>
        <li>дробилка измельчения компонентов;</li>
        <li>смеситель для перемешивания компонентов;</li>
        <li>виброгрохот</li>
        <li>просеивающие установки (сита)</li>
        <li>сушилки и бункеры</li>
        <li>дозирующие устройства</li>
      </ul>
    </div>


    <div v-rc>
      <h5>Ассортимент</h5>
      <p>Сухие сапропеле-грунтовые смеси на сегодняшний момент являются наиболее динамично развивающимся
         направлением в производстве удобрений, почвообразователей и рекультивантов</p>
      <ul>
        <li>сапропеле-грунтовые смеси как удобрения</li>
        <li>универсальные грунтовые смеси для почвообразования и рекультивации</li>
        <li>грунтовые садовые смеси и земля</li>
        <li>сапропелевые добавки, сорбирующие тяжелые металлы и радионуклиды</li>
        <li>сухие сапропелевые кормовые и сапропеле-витаминные добавки</li>
        <li>сапропелевые тепличные почвообразователи и грунты</li>
        <li>сапропелевые грунтовые смеси для городского и приусадебного озеленения</li>
        <li>сапропелевые грунты для производства рулонных газонов</li>
        <li>грунтовые и сапропелевые смеси по рецептуре заказчика</li>
      </ul>
    </div>

    <div v-rc>
      <h5>Области применения, Решение для переработки</h5>
      <ul>
        <li>Торфяная промышленность</li>
        <li>Растениеводство</li>
        <li>Животноводство</li>
        <li>Плодоовощная</li>
        <li>Энергетическая, биогазовая</li>
      </ul>
    </div>

    ного помета



    <div v-rc>
      <h5>Этапы производства удобрения из куриного помета</h5>
      <ul>
        <li>Предв. измельчение</li>
        <li>Сушка и измельчение</li>
        <li>Гранулирование</li>
        <li>Охлаждение и просевФасовка</li>
      </ul>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String,
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
