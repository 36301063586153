import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/tehnologicheskie-linii',
    name: 'Technology',
    component: () => import('../views/Technology.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue'),
  },
  {
    path: '/oborudovanie/drobilka-centrobezhnay',
    name: 'DrobilkaCentrobezhnay',
    component: () => import('../views/oborudovanie/DrobilkaCentrobezhnay.vue'),
  },
  {
    path: '/oborudovanie/drobilka-molotkovaya',
    name: 'DrobilkaMolotkovaya',
    component: () => import('../views/oborudovanie/DrobilkaMolotkovaya.vue'),
  },
  {
    path: '/oborudovanie/granulyatori-barabannyi',
    name: 'GranulyatoriBarabannyi',
    component: () => import('../views/oborudovanie/GranulyatoriBarabannyi.vue'),
  },
  {
    path: '/oborudovanie/granulyatori-lopastnoy',
    name: 'GranulyatoriLopastnoy',
    component: () => import('../views/oborudovanie/GranulyatoriLopastnoy.vue'),
  },
  {
    path: '/oborudovanie/grohota-vysokochastotnyie',
    name: 'GrohotaVysokochastotnyie',
    component: () => import('../views/oborudovanie/GrohotaVysokochastotnyie.vue'),
  },
  {
    path: '/oborudovanie/melnitsy-valkovyie',
    name: 'MelnitsyValkovyie',
    component: () => import('../views/oborudovanie/MelnitsyValkovyie.vue'),
  },
  {
    path: '/oborudovanie/pitateli-shnekovyie',
    name: 'PitateliShnekovyie',
    component: () => import('../views/oborudovanie/PitateliShnekovyie.vue'),
  },
  {
    path: '/oborudovanie/smesiteli-lopastnyie',
    name: 'SmesiteliLopastnyiei',
    component: () => import('../views/oborudovanie/SmesiteliLopastnyiei.vue'),
  },
  {
    path: '/oborudovanie/smesiteli-pluzhnyie',
    name: 'SmesiteliPluzhnyie',
    component: () => import('../views/oborudovanie/SmesiteliPluzhnyie.vue'),
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
