

document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, {});
  });







// document.addEventListener('DOMContentLoaded', event => {
//   const options = {
//     duration: 300,
//     onShow: null,
//     swipeable: false,
//     responsiveThreshold: Infinity,
//   };
//   const el = document.querySelector('.tabs');
//   M.Tabs.init(el, options);
// });
//
// document.addEventListener('DOMContentLoaded', function () {
//   var elems = document.querySelectorAll('select');
//   var instances = M.FormSelect.init(elems, {});
// });
//
// document.addEventListener('DOMContentLoaded', function () {
//   var elems = document.querySelectorAll('.carousel');
//   var instances = M.Carousel.init(elems, {});
// });
//
document.addEventListener('DOMContentLoaded', function () {
  var elems = document.querySelectorAll('input[type=range]');
  var instances = M.Range.init(elems);
});
//
// document.addEventListener('DOMContentLoaded', function () {
//   var elems = document.querySelectorAll('.modal');
//   var instances = M.Modal.init(elems, {});
// });

document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, {});
  });





document.addEventListener('DOMContentLoaded', function() {
  var options = {

    edge: 'left',  //Сторона экрана, на которой отображается Sidenav.
    draggable: true,  //Разрешить жесты смахивания открывать / закрывать Sidenav.
    inDuration: 300,  //Длина входящего перехода в мс.
    outDuration: 200, //Длина выходного перехода в мс.
    onOpenStart: null,  //Функция вызывается при входе в sidenav.
    onOpenEnd: null,  //Функция вызывается, когда sidenav завершает ввод.
    onCloseStart: null, //Функция вызывается при выходе из sidenav.
    onCloseEnd: null, //Функция вызывается после выхода из sidenav.
    preventScrolling: true, //Запретить прокрутку страницы, пока открыта боковая навигация.
  };
    var elems = document.querySelectorAll('.sidenav');
    var instances = M.Sidenav.init(elems, options);
  });


  document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.fixed-action-btn');
    var instances = M.FloatingActionButton.init(elems,

      {
      direction: 'left',
      hoverEnabled: false
    }


      );
  });


