import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';


//createApp(App).use(store).use(router).mount('#app')
const app = createApp(App);
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


import M from 'materialize-css';

// document.addEventListener('DOMContentLoaded', function () {
//   M.AutoInit();
// });
require('./assets/js/materializecss-init');



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Мои компоненты



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Глобальные Дерективы
import { RandomColor } from './directives/random-color';

app.directive('rc', RandomColor);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
app
  .use(store)
  .use(router)
  .mount('#app');





