<template>

  <!-- Dropdown Structure -->
<ul id="dropdown1" class="dropdown-content">

  <li><router-link to="/oborudovanie/grohota-vysokochastotnyie">Грохота вибрационные высокочастотные</router-link></li>
  <li><router-link to="/oborudovanie/melnitsy-valkovyie">Мельница валковая</router-link></li>
  <li><router-link to="/oborudovanie/drobilka-molotkovaya">Дробилка молотковая</router-link></li>
  <li><router-link to="/oborudovanie/drobilka-centrobezhnay">Центробежно-ударная дробилка</router-link></li>
  <li><router-link to="/oborudovanie/granulyatori-barabannyi">Грануляторы барабанный</router-link></li>
  <li><router-link to="/oborudovanie/granulyatori-lopastnoy">Грануляторы лопастной</router-link></li>
  <li><router-link to="/oborudovanie/smesiteli-lopastnyie">Смесители лопастные</router-link></li>
  <li><router-link to="/oborudovanie/smesiteli-pluzhnyie">Смесители плужные</router-link></li>
  <li><router-link to="/oborudovanie/pitateli-shnekovyie">Питатели шнековые</router-link></li>
</ul>
<nav>
  <div class="nav-wrapper">
    <a href="#!" class="brand-logo">ТД завода Проммаш</a>
    <ul class="right hide-on-med-and-down">
      <li><router-link to="/">Главная</router-link></li>
      <li><router-link to="/about">О нас</router-link></li>
      <li><router-link to="/tehnologicheskie-linii">Технологические линии</router-link></li>
      <li><a class="dropdown-trigger" href="#!" data-target="dropdown1">Оборудование<i class="material-icons right">arrow_drop_down</i></a></li>
      <li><router-link to="/contacts">Контакты</router-link></li>
    </ul>
  </div>
</nav>



  <router-view/>
</template>

<script>
  export default {

    components: {},

    mixins: [],

    props: {},

    data() {
      return {};
    },

    computed: {},

    watch: {},

    mounted() {
      console.log('===========smesiteli=========================')

 M.AutoInit()

   document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.dropdown-trigger');
    var instances = M.Dropdown.init(elems, options);
  });



    },

    methods: {},
  };
</script>





<style lang="scss">
/*@import "~materialize-css/sass/materialize";*/
</style>
