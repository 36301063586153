//import Vue from 'vue';

function randomColor() {
  let color;
  color = 'rgb(' + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 255) + ',' + Math.round(
    Math.random() * 255) + ')';

  return color;
}

export const RandomColor = {

  mounted(el, binding, vnode) {

    el.style.color = randomColor();
    //el.style.border = '2px solid ' + randomColor();
    el.style.outline = '2px solid ' + randomColor();
    el.style.outlineOffset = '-2px';
    //el.style.backgroundColor = randomColor();
    // document.body.children

    if (binding.arg == 'child') {
      console.log('ON: v-rc:child');

      let elements = el.querySelectorAll('div');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dotted ' + randomColor();
        el.style.outlineOffset = '-1px';
      });


    }
    if (binding.arg == 'debug') {

      console.log('ON: v-rc:debug');

      let elements = document.querySelectorAll('ul > *:not(li)');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dashed red';
        el.style.outlineOffset = '-1px';
      });

      elements = document.querySelectorAll('ol > *:not(li)');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dashed red';
        el.style.outlineOffset = '-1px';
      });

      elements = document.querySelectorAll('dl > *:not(dt):not(dd)');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dashed red';
        el.style.outlineOffset = '-1px';
      });

      elements = document.querySelectorAll('img:not([alt])');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dashed lime';
        el.style.outlineOffset = '-1px';
      });

      elements = document.querySelectorAll('label:not([for])');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dashed lime';
        el.style.outlineOffset = '-1px';
      });

      elements = document.querySelectorAll('input[type=submit]:not([value])');
      elements.forEach.call(elements, function (el) {
        el.style.border = '1px dashed lime';
        el.style.outlineOffset = '-1px';
      });

    }

  },

};


// Глобальные Дерективы
// import { RandomColor } from '@/directives/random-color/index';
// Vue.directive('rc', RandomColor)

// В Компанент
// import { RandomColor } from '@/directives/random-color/index';
// export default {
//   directives: {
//     rc:RandomColor
//   }
// }


//  Debug css
// Удобно для отладки и самоконтроля искать/подсвечивать картинки без alt, label без for и другие ошибки.
//
// /* подсвечиваем теги без необходимых атрибутов */
// img:not([alt]),
// label:not([for]),
// input[type=submit]:not([value]) {
//   outline:2px solid red;
// }
//
// /* тревога, если первый child внутри списка не li и прочие похожие примеры */
// ul > *:not(li),
// ol > *:not(li),
// dl > *:not(dt):not(dd) {
//   outline:2px solid red;
// }


// BODY > * {
//     outline: 2px solid deeppink;
// }
// BODY > * > * {
//     outline: 2px dashed lime;
//     outline-offset: -2px;
// }
// BODY > * > * > * {
//     outline: 2px dotted dodgerblue;
//     outline-offset: -4px;
// }